/* WORKINQUIRY STYLING */
.WorkInquiry {
  background-color: var(--primary-color);
  border-radius: 10px;
  max-width: 1100px;
  max-height: 180px;
  margin-bottom: 80px;
}
.WorkInquiry .row {
  margin-top: 80px;
  padding-bottom: 25px;
}
.WorkInquiry .left-side {
  padding: 35px 0 0 115px;
}
.WorkInquiry h3 {
  font-family: var(--primary-font);
  font-size: 48px;
}
.WorkInquiry p {
  font-family: var(--secondary-font);
}
.WorkInquiry .right-side {
  padding: 55px 0 0 250px;
}

/* NAVIGATION STYLING */
.Navigation {
  font-family: var(--secondary-font);
}

.Navigation .active {
  color: var(--secondary-color) !important;
}
.Navigation .navigation-bar {
  background-color: var(--tertiary-color) !important;
  left: 0;
}
.Navigation .my-name-nav {
  color: var(--secondary-color) !important;
  margin-right: 120px;
  margin-left: 30px;
}

.Navigation .nav-link {
  margin-right: 10px;
}

/* FOOTER STYLING */
footer {
  margin-top: 60px;
}
.social-links a {
  background-color: var(--primary-color);
  border-radius: 50%;
  color: var(--secondary-color);
  margin: 0 20px;
  margin-bottom: 40px;
  padding: 10px 14px;
}
