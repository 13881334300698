.Contact {
  margin-top: 50px;
}
.Contact h1 {
  font-family: var(--primary-font);
  font-size: 64px;
  margin-bottom: 60px;
}
.Contact h2 {
  font-family: var(--secondary-font);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  margin-top: 24px;
}
.Contact a {
  color: var(--secondary-color);
  font-family: var(--secondary-font);
}
.Contact .author {
  color: var(--font-color);
  font-family: var(--secondary-font);
  font-size: 12px;
  margin-top: 180px;
}
.Contact {
  background-color: var(--primary-color);
}
.Contact .contact-info {
  margin-left: 180px;
  padding-top: 140px;
  padding-bottom: 80px;
}
.Contact .email-title {
  display: inline;
  font-family: var(--secondary-font);
  font-size: 18px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .Contact .contact-info {
    margin-left: 30px;
  }
}
