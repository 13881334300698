:root {
  --primary-font: "Playfair Display", serif;
  --secondary-font: "Poppins", sans-serif;
  --font-color: #8e8e8e;
  --primary-color: #f9f7fe;
  --secondary-color: #6c63ff;
  --tertiary-color: white;
}
.button-purple {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 0.25rem;
  color: var(--tertiary-color);
  display: inline-block;
  font-family: var(--secondary-font);
  font-size: 18px;
  padding: 15px 30px;
  text-decoration: none;
  transition: color 0.15s ease-in-out;
}
.button-purple:hover {
  background-color: var(--secondary-color);
  color: black;
}
.button-white {
  background-color: var(--tertiary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 0.25rem;
  color: var(--secondary-color);
  display: inline-block;
  font-size: 18px;
  margin: 20px 0;
  padding: 15px 30px;
  text-decoration: none;
  transition: color 0.15s ease-in-out;
}
.button-white:hover {
  color: black;
}
