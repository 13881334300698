.AllProjects img {
  border-radius: 10px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 8%);
}
.AllProjects .each-project {
  margin-bottom: 40px;
}

.Portfolio h1 {
  font-family: var(--primary-font);
  font-size: 45px;
  margin-bottom: 50px;
}
.Portfolio {
  padding: 160px 20px 70px 20px;
  text-align: center;
}
.Portfolio h2 {
  font-family: var(--secondary-font);
  line-height: 1.5;
  margin-top: 16px;
}
.Portfolio .github-project-link {
  color: var(--secondary-color);
  font-family: var(--secondary-font);
  font-size: 14px;
}

@media (max-width: 768px) {
  img {
    max-width: 100px;
  }
  .AllProjects img {
    height: auto;
    max-width: 100%;
  }
}
