.AboutMe {
  max-width: 100%;
}
.AboutMe .about-me-hero {
  background-color: var(--primary-color);
}
.AboutMe h1 {
  font-family: var(--primary-font);
  font-size: 66px;
  margin-bottom: 24px;
  margin-top: 35px;
}
.AboutMe h2 {
  font-family: var(--secondary-font);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
}
.AboutMe .about-button {
  padding: 15px 20px;
  margin: 20px 0 40px 0;
}
.AboutMe .my-photo {
  border-radius: 25px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 8%);
}
.AboutMe .about-me-container {
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding-bottom: 40px;
  padding-top: 140px;
}
.AboutMe p {
  color: var(--font-color);
  font-family: var(--secondary-font);
  font-size: 16px;
  line-height: 30px;
}
.AboutMe .initial-description {
  max-width: 95%;
}
.AboutMe .practical-title {
  font-family: var(--primary-font);
  font-size: 36px;
  text-align: center;
}
.AboutMe .practical-info {
  margin-bottom: 60px;
}
.AboutMe .practical-info .card {
  border-radius: 35px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 8%);
  font-family: var(--secondary-font);
  margin: 0 auto;
  margin-top: 20px;
  min-height: 72px;
  max-width: 350px;
}

.AboutMe .practical-info .icon {
  background-color: var(--primary-color);
  border-radius: 50%;
  color: var(--secondary-color);
  font-size: 20px;
  margin-right: 10px;
  padding: 10px 10px;
  justify-content: center;
  vertical-align: middle;
}

.AboutMe .language {
  margin-right: 10px;
}
.AboutMe .progress-bar {
  background-color: var(--tertiary-color);
  display: inline;
  line-height: 15px;
}
.AboutMe .bullet {
  border: 1px solid var(--secondary-color);
  border-radius: 50%;
  display: inline-block;
  height: 15px;
  margin: 10px 10px 10px 0;
  vertical-align: middle;
  width: 15px;
}
.AboutMe .fill {
  background-color: var(--secondary-color);
}

.Resume h2 {
  font-family: var(--primary-font);
  font-size: 36px;
}

.Resume .purple-shape {
  background-color: var(--secondary-color);
  border-radius: 20px;
  height: 6px;
  width: 70px;
}
.Resume .gray-text {
  color: var(--font-color);
}
.Resume .education-title {
  font-weight: 700;
  margin-bottom: 10px;
}
.Resume .education-cards-col {
  padding-left: 0;
  padding-right: 0;
}
.Resume .col-3 .degree-card {
  display: block;
  margin: auto;
}
.Resume .past-life .card {
  border-radius: 10px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 8%);
  font-family: var(--secondary-font);
  font-size: 14px;
  height: 255px;
  margin-bottom: 30px;
  width: 225px;
}
.Resume .past-life .card-body {
  padding: 34px 26px;
}
.Resume i {
  background-color: var(--primary-color);
  border-radius: 50%;
  color: var(--secondary-color);
  display: block;
  font-size: 40px;
  height: 70px;
  margin-bottom: 20px;
  padding-top: 12px;
  text-align: center;
  width: 70px;
}
.Resume .second-col-edu {
  margin-top: 12%;
}
.Resume .smallscreen {
  display: none;
}
.WorkHistory {
  font-family: var(--secondary-font);
}

.WorkHistory button {
  color: #d2cdf4;
}
.WorkHistory .work-history-row {
  padding: 0 12px;
}
.WorkHistory button:hover {
  color: var(--secondary-color);
}
.WorkHistory .nav-link.active {
  color: var(--secondary-color);
  font-weight: 500;
}

.WorkHistory .tmflogo,
.WorkHistory .esglogo {
  border-radius: 15px;
}
.WorkHistory img {
  margin-bottom: 15px;
}
.esglogo {
  border: 3px solid;
}
.WorkHistory p {
  color: var(--font-color);
  margin-top: 15px;
}

.WorkHistory .past-life-paragraph {
  margin-bottom: 80px;
  padding: 0;
}
.WorkHistory h2 {
  padding: 0;
}
.WorkHistory button {
  min-width: 155px;
}

.Coding {
  padding: 10px 0;
}
.Coding .gray-text {
  color: var(--font-color);
}
.Coding .company {
  margin: 0px 10px 10px 0px;
  font-weight: 700;
}
.Coding .school {
  margin-bottom: 20px;
}
.cert {
  margin-bottom: 5px;
}

.Coding .coding-certifications {
  font-family: var(--secondary-font);
}
.Coding .coding-certifications .status {
  color: var(--secondary-color);
}
.Coding h2 {
  font-family: var(--primary-font);
  font-size: 36px;
}
.Coding .title {
  font-weight: 700;
  margin-bottom: 5px;
}
.Coding .certificate-name {
  line-height: 1.8;
}
.Coding .purple-shape {
  margin-bottom: 20px;
}
.Technologies {
  margin-top: 120px;
}
.Technologies .card {
  border-radius: 10px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 8%);
  height: 145px;
  width: 125px;
}
.Technologies .tech-name {
  font-family: var(--secondary-font);
  padding-top: 15px;
}
.tech-row .tech-col {
  margin-bottom: 15px;
  padding: 0 8px;
  text-align: center;
}
.Technologies .tech-row {
  display: flex;
  flex-wrap: wrap;
}
.Technologies .button-purple {
  margin-top: 20px;
}
.Technologies img {
  background-color: var(--primary-color);
  border-radius: 50%;
  height: 60px;
  padding: 8px 8px;
  max-width: 60px;
}
.Technologies .technology-row p {
  max-width: 95%;
}
@media (max-width: 768px) {
  .AboutMe .about-me-description {
    text-align: center;
  }
  .AboutMe .photo-column {
    display: none;
  }
  .AboutMe .initial-description {
    max-width: 100%;
  }
  .AboutMe .about-button {
    margin-bottom: 40px;
  }
  .AboutMe section {
    margin-top: 40px;
  }
  .Resume .card {
    max-width: 140px;
    max-height: 155px;
  }
  .Resume .card .card-body {
    font-size: 11px;
    text-align: center;
    padding: 10px 5px 0 5px;
  }
  .Resume i {
    font-size: 25px;
    height: 50px;
    margin: 4px auto;
    width: 50px;
  }
  .Resume .education-row {
    justify-content: center;
  }
  .Resume .education-cards-col {
    width: 30%;
  }
  .Resume .second-col-edu {
    margin-left: 45px;
  }
  .Resume .smallscreen {
    display: block;
    text-align: center;
  }

  .WorkHistory {
    text-align: center;
    margin-top: 20px;
  }
  .WorkHistory .work-history-row {
    display: none;
  }
  .WorkHistory .experience-box {
    padding: 15px 15px;
  }
  .WorkHistory .experience-box p {
    font-size: 12px;
    line-height: 15px;
  }
  .WorkHistory .ICTY {
    font-size: 12px;
    line-height: 10px !important;
  }
  .Coding {
    text-align: center;
  }
  .Coding .purple-shape {
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .Coding .coding-certifications {
    display: flex;
    flex-direction: column;
  }
  .Coding h2 {
    margin-top: 20px;
  }
  .Technologies {
    margin-top: 0px;
  }
  .Technologies .portfolio-button {
    margin-bottom: 40px;
  }
  .Technologies .tech-row {
    justify-content: center;
  }
  .Technologies .tech-background {
    padding: 25px;
  }
}

.explain-cols {
  display: flex;
  flex-direction: column;
}
